import { Component, EventEmitter, Input,  Output } from '@angular/core';

export interface ItemMultiSelect {
  id: string;
  name: string;
  parent_id?: string;
  selected?: boolean;
}
@Component({
    selector: 'app-multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: ['./multiselect.component.scss'],
    standalone: false
})
export class MultiselectComponent  {
  @Input() items: ItemMultiSelect[] = [];
  @Input() selectedItems: ItemMultiSelect[] | ItemMultiSelect = [];
  @Input() placeholder: string = 'select an option';
  @Input() disabled: boolean = false;
  @Input() labelId: string = 'name';
  @Input() multiple: boolean = true;
  @Input() closeOnSelect: boolean = false;
  @Output() change = new EventEmitter<ItemMultiSelect[]>();
  @Output() changeOneItem = new EventEmitter<ItemMultiSelect>();
  @Output() add = new EventEmitter<ItemMultiSelect[]>();
  @Output() remove = new EventEmitter<ItemMultiSelect[]>();
  @Output() clear = new EventEmitter();

  customSearchFn(term: string, item: any): boolean {
    return item.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1;
  }

  onChange(event: ItemMultiSelect[] | ItemMultiSelect): void {
    if (this.multiple) {
      this.change.emit(event as ItemMultiSelect[]);
    } else {
      this.changeOneItem.emit(event as ItemMultiSelect);
    }
  }
  onAdd(event: ItemMultiSelect[]): void {
    this.add.emit(event);
  }
  onRemove(event: ItemMultiSelect): void {
    this.selectedItems = (this.selectedItems as ItemMultiSelect[]).filter(
      (item: { id: string }) => item.id !== event.id
    );
    this.change.emit(this.selectedItems);
  }

  onClear(): void {
    this.clear.emit();
  }
  onClick() {
    setTimeout(() => {
      const activeElement = document.activeElement as HTMLElement;
      if (activeElement && typeof activeElement.blur === 'function') {
        activeElement.blur();
      }
    }, 100);
  }
}
