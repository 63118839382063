import { ClipboardModule } from '@angular/cdk/clipboard';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { BaseChartDirective } from 'ng2-charts';
import { SellerDetailComponent } from '../modules/seller/seller-detail/seller-detail.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { ClientInfoComponent } from './components/client-info/client-info.component';
import { ClientItemComponent } from './components/client-item/client-item.component';
import { CommentCardComponent } from './components/comment-card/comment-card.component';
import { SellerReportFeedbackComponent } from './components/dsh-report-seller-feedback/dsh-report-seller-feedback.component';
import { SellerFeedbackComponent } from './components/dsh-seller-feedback/seller-feedback.component';
import { SellerItemComponent } from './components/dsh-seller-item/seller-item.component';
import { SellerProgressBarComponent } from './components/dsh-seller-progress-bar/seller-progress-bar.component';
import { SellerSellMetricsComponent } from './components/dsh-seller-sell-metrics/dsh-seller-sell-metrics.component';
import { FilterAccessComponent } from './components/filter-access/filter-access.component';
import { HandsComponent } from './components/hands/hands.component';
import { LastUpdateInfoComponent } from './components/last-update-info/last-update-info.component';
import { ChartLineComponent } from './components/line-chart/line-chart.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { ProductItemComponent } from './components/product-item/product-item.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SearchboxComponent } from './components/searchbox/searchbox.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { UpsertCommentComponent } from './components/upsert-comment/upsert-comment.component';
import { AutofocusDirective } from './directive/autofocus.directive';
import { MaterialModule } from './material.module';
import { EmptyPipe } from './pipe/empty.pipe';
import { FilterListPipe } from './pipe/filter.pipe';
import { TitleCasePipe } from './pipe/title-case.pipe';
import {SellerDetailHeaderComponent} from "./components/seller-detail-header/seller-detail-header.component";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  SellerStatisticsDetailComponent
} from "./components/seller-statistics-detail/seller-statistics-detail.component";
import {ContainerBarComponent} from "./components/charts/container-bar/container-bar.component";
import {ChartBarComponent} from "./components/charts/bar/bar.component";
import {ContainerFrequencyComponent} from "./components/charts/container-frequency/container-frequency.component";
import {BarChartComponent} from "./components/bar-chart/bar-chart.component";

export const COMPONENT = [
  FilterAccessComponent,
  SearchboxComponent,
  ClientItemComponent,
  FilterListPipe,
  EmptyPipe,
  TitleCasePipe,
  MultiselectComponent,
  SpinnerComponent,
  ClientInfoComponent,
  ProductItemComponent,
  HandsComponent,
  ProgressBarComponent,
  LastUpdateInfoComponent,
  AlertMessageComponent,
  AutofocusDirective,
  CommentCardComponent,
  UpsertCommentComponent,
  SellerItemComponent,
  SellerFeedbackComponent,
  SellerProgressBarComponent,
  SellerDetailComponent,
  SellerSellMetricsComponent,
  SellerReportFeedbackComponent,
  SellerDetailHeaderComponent,
  SellerStatisticsDetailComponent,
  ContainerBarComponent,
  ChartBarComponent,
  ContainerFrequencyComponent
];
@NgModule({
  declarations: [...COMPONENT],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    NgOptimizedImage,
    NgSelectModule,
    FormsModule,
    RouterModule,
    ScrollingModule,
    ClipboardModule,
    BaseChartDirective,
    ChartLineComponent,
    BarChartComponent,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    NgOptimizedImage,
    NgSelectModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    ...COMPONENT,
  ],
  providers: [FormGroupDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
