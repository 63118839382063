<div>
  <canvas
    baseChart
    #canvasRef
    class="canvas-live"
    [type]="'bar'"
    [data]="chartDataConfiguration"
    [options]="lineChartOptions"
    [height]="heightPx"
  >
  </canvas>
</div>
