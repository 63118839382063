import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild,} from '@angular/core';
import {ChartConfiguration, ChartDataset, ChartOptions} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {DatePipe} from "@angular/common";

@Component({
    selector: 'dsh-chart-bar',
    imports: [BaseChartDirective],
    templateUrl: './bar-chart.component.html',
    styleUrl: './bar-chart.component.scss'
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input() chartData!: any[];
  @Input() heightPx: string = '250';
  @Input() xScale: string = '';
  @Input() yScale: string = '';
  @Input() showLegend: boolean = false;
  @Input() showGrid: boolean = false;

  chartDataConfiguration: ChartConfiguration<'bar'>['data'] = {
    datasets: [],
  };

  lineChartOptions!: ChartOptions<'bar'>;
  datePipe: DatePipe;

  @ViewChild(BaseChartDirective) chartDirective?: BaseChartDirective;
  @ViewChild('canvasRef') canvasRef?: ElementRef<HTMLCanvasElement>;

  constructor() {
    this.setChartOptions();
    this.datePipe = new DatePipe('en-US');
  }
  ngOnInit(): void {
    this.updateChartBarData(this.chartData);
    this.updateChartColors();
    this.setChartOptions();

    this.lineChartOptions.plugins!.legend!.display = this.showLegend;
  }

  ngOnChanges(): void {
    this.updateChartBarData(this.chartData);
    this.updateChartColors();
  }
  ngAfterViewInit() {
    this.updateChartColors();
  }

  private updateChartBarData(newData: any[]) {
    if (!newData || newData.length === 0) {
      return;
    }
    newData = this.processDataIndividualBar(newData);

    this.chartDataConfiguration.labels = newData.map(
      (data: any) => this.datePipe.transform(new Date(data.day), 'dd-MM')
    );
    this.chartDataConfiguration.datasets = [];

    this.chartDataConfiguration.datasets.push({
      data: newData.map((data) => data.value),
    });
  }
  processDataIndividualBar(data: any) {
    const latestPeriod = new Date(Math.max(new Date(data.at(-1).day).getTime()));
    const last4Weeks = new Date(
      latestPeriod.getFullYear(),
      latestPeriod.getMonth() - 1,
      1
    );

    data = data.filter((d: any) => {
      const dataDate = new Date(d.day);
      return dataDate >= last4Weeks;
    });
    return data;
  }

  private updateChartColors() {
    const ctx = this.canvasRef?.nativeElement.getContext('2d');
    if (ctx) {
      this.chartDataConfiguration.datasets.forEach(
        (dataset: ChartDataset<'bar'>) => {
          dataset.backgroundColor = '#f76902';
          dataset.borderColor = '#f76902';
        }
      );

      this.chartDirective?.update();
    }
  }

  private setChartOptions() {
    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          grid: {
            display: this.showGrid,
          },
          title: {
            color: '#9398a3',
            display: true,
            text: this.yScale,
            font: {
              weight: 'bold',
            },
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            display: this.showGrid,
          },
          title: {
            color: '#9398a3',
            display: true,
            align: 'end',
            text: this.xScale,
            font: {
              weight: 'bold',
            },
          },
        },
      },

      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
        },
        title: {
          display: false,
        },
      },
    };
  }
}
