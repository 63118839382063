import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  ApplicationInsights,
  IExceptionTelemetry,
  IMetricTelemetry,
  IPageViewTelemetry,
  ITraceTelemetry,
} from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';
import { AmplitudeService } from './amplitude.service';
import { ApiBridgeService } from './api-bridge.service';
import { StorageKey, StorageService } from './storage.service';

export enum EventName {
  LoginSuccess = 'LOGIN',
  Logout = 'LOGOUT',
  ErrorTokenLogout = 'ERROR - TOKEN LOGOUT',
  NotFoud = 'NOT FOUND',
  Filter = 'FILTER',
  RecommendationClick = 'RECOMMENDATIONS - CLICK',
  RecommendationInit = 'RECOMMENDATIONS - INIT',
  RecommendationFeedback = 'RECOMMENDATION - FEEDBACK',
  CommentInit = 'COMMENT - INIT',
  CommentCreation = 'COMMENT - CREATE',
  CommentModification = 'COMMENT - MODIFY',
  FilterProducts = 'FILTER - PRODUCTS',
  AccessDenied = 'ACCESS DENIED',
  HomeSuccess = 'HOME - SUCCESS',
  AccessError = 'ACCESS - ERROR',
  UserDataError = 'USER DATA - ERROR',
}

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private readonly appInsights: ApplicationInsights;

  constructor(
    private readonly authService: MsalService,
    private readonly apiBridgeService: ApiBridgeService,
    private readonly amplitudeService: AmplitudeService,
    private readonly storageService: StorageService
  ) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrumentationKey,
        enableAutoRouteTracking: true,
      },
    });
  }

  init(metricsEnabled: boolean): void {
    if (metricsEnabled) {
      this.appInsights.loadAppInsights();
    }
  }

  logPageView(name?: string, url?: string): void {
    const pageView: IPageViewTelemetry = { name, uri: url };
    this.appInsights.trackPageView(pageView);
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    const isImpersionation = this.storageService.getData(
      StorageKey.IsImpersonating
    );
    if (isImpersionation === false || isImpersionation === null) {
      properties?.['client_id'] &&
        (properties['client_id'] = Number(properties['client_id']));
      const eventProperties = {
        userName: this.authService.instance.getActiveAccount()?.username,
        ...properties,
      };
      this.appInsights.trackEvent({ name }, eventProperties);
      this.logEventFligoo({ event: name, ...eventProperties });
      this.logEventAmplitude(name, eventProperties);
    } else {
      console.error('ACTIVE IMPERSONATION - EVENT NOT LOGGED');
    }
  }

  logEventFligoo(eventProperties: any): void {
    this.apiBridgeService.post('user_logs', eventProperties).subscribe();
  }

  logEventAmplitude(eventName: string, eventData?: any): void {
    this.amplitudeService.trackEvent(eventName, eventData);
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ): void {
    const metric: IMetricTelemetry = { name, average };
    this.appInsights.trackMetric(metric, properties);
  }

  logException(
    exception: Error | HttpErrorResponse,
    severityLevel?: number
  ): void {
    const apiUrl =
      exception instanceof HttpErrorResponse ? exception.url : undefined;
    const httpStatus =
      exception instanceof HttpErrorResponse ? exception.status : undefined;
    const exceptionTelemetry: IExceptionTelemetry = {
      exception,
      severityLevel,
    };

    const properties = {
      username: this.authService.instance.getActiveAccount()?.username,
      message: exception.message,
      url: apiUrl,
      httpStatus,
    };

    console.error('Exception logged', properties);
    this.appInsights.trackException(exceptionTelemetry, properties);
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    const trace: ITraceTelemetry = { message };
    this.appInsights.trackTrace(trace, properties);
  }
}
