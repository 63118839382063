import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'empty',
    standalone: false
})
export class EmptyPipe implements PipeTransform {
  transform(value: any[]): any {
    return value && value.length > 0 ? value : [true];
  }
}
